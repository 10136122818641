import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
	
 
<path d="M2000 3406 l0 -475 43 -36 c24 -20 62 -48 85 -61 l42 -25 0 489 0
488 -77 45 c-42 24 -80 46 -85 47 -4 2 -8 -210 -8 -472z"/>
<path d="M2230 3217 l0 -443 85 -42 85 -42 0 439 0 439 -80 46 c-43 25 -82 46
-85 46 -3 0 -5 -199 -5 -443z"/>
<path d="M2450 3526 c0 -10 233 -136 280 -151 194 -62 344 -219 395 -411 25
-95 21 -228 -10 -321 -49 -145 -152 -268 -280 -335 -57 -29 -61 -34 -81 -92
-11 -34 -24 -69 -28 -78 -7 -15 1 -14 65 7 198 68 356 218 436 417 112 278 24
616 -212 807 -132 108 -285 161 -462 161 -56 0 -103 -2 -103 -4z"/>
<path d="M2460 3055 l0 -394 73 -48 c39 -26 78 -52 85 -56 9 -6 12 75 12 397
l0 404 -80 46 c-43 25 -82 46 -85 46 -3 0 -5 -178 -5 -395z"/>
<path d="M1943 3028 c-58 -63 -78 -139 -52 -191 32 -63 134 -138 334 -247 264
-143 365 -251 365 -386 0 -76 -29 -139 -106 -230 -54 -63 -55 -66 -19 -37 76
59 192 188 221 243 22 42 28 69 28 115 0 144 -94 232 -429 406 -220 113 -290
161 -321 221 -17 32 -18 81 -4 109 14 25 8 24 -17 -3z"/>
<path d="M1856 2843 c-3 -3 -6 -33 -6 -65 0 -119 65 -187 320 -338 137 -81
191 -119 247 -175 63 -63 74 -79 90 -136 l18 -63 11 39 c21 67 17 144 -10 201
-43 93 -110 144 -404 308 -126 70 -212 141 -239 195 -11 22 -23 38 -27 34z"/>
<path d="M2020 2310 c-16 -16 -20 -33 -20 -95 0 -117 -4 -115 240 -115 220 0
216 -1 179 60 -47 78 -51 80 -170 80 l-107 0 -6 35 c-11 55 -76 75 -116 35z"/>
<path d="M1680 1815 l0 -85 75 0 c100 0 142 17 150 62 15 79 -27 108 -157 108
l-68 0 0 -85z m141 35 c11 -6 19 -21 19 -35 0 -28 -26 -45 -69 -45 -30 0 -31
1 -31 45 0 44 1 45 31 45 17 0 39 -5 50 -10z"/>
<path d="M2021 1818 l-50 -83 31 -3 c21 -2 34 2 40 12 12 22 113 23 122 1 5
-14 66 -22 66 -9 0 4 -21 42 -47 85 -44 71 -51 79 -80 79 -29 0 -36 -7 -82
-82z m87 -16 c-27 -5 -32 2 -18 29 l13 22 13 -24 c11 -22 11 -24 -8 -27z"/>
<path d="M2300 1894 c0 -4 19 -42 42 -85 38 -72 44 -79 72 -79 28 0 35 7 74
79 23 43 42 81 42 85 0 3 -13 6 -30 6 -27 0 -33 -6 -58 -62 l-28 -62 -27 62
c-24 56 -29 62 -57 62 -16 0 -30 -3 -30 -6z"/>
<path d="M2610 1815 l0 -85 30 0 30 0 0 85 0 85 -30 0 -30 0 0 -85z"/>
<path d="M2760 1815 l0 -85 35 0 34 0 3 55 3 56 43 -56 c39 -50 47 -55 83 -55
l39 0 0 85 0 85 -35 0 -35 0 0 -52 -1 -53 -47 53 c-41 46 -52 52 -84 52 l-38
0 0 -85z"/>
<path d="M3122 1815 l-52 -85 35 0 c22 0 38 6 41 15 4 11 21 15 59 15 38 0 55
-4 59 -15 3 -9 19 -15 41 -15 l35 0 -52 85 c-47 77 -55 85 -83 85 -28 0 -36
-8 -83 -85z m108 -7 c0 -4 -11 -8 -25 -8 -28 0 -30 3 -14 32 11 21 12 21 25 3
8 -10 14 -22 14 -27z"/>
<path d="M1860 1580 c0 -5 7 -10 15 -10 11 0 15 -11 15 -40 0 -22 5 -40 10
-40 6 0 10 18 10 40 0 29 4 40 15 40 8 0 15 5 15 10 0 6 -18 10 -40 10 -22 0
-40 -4 -40 -10z"/>
<path d="M2200 1540 c0 -38 4 -50 15 -50 11 0 15 12 15 50 0 38 -4 50 -15 50
-11 0 -15 -12 -15 -50z"/>
<path d="M2042 1558 c-16 -16 -15 -43 3 -58 17 -14 47 -10 53 9 3 9 -2 11 -17
6 -12 -4 -21 -2 -21 4 0 6 11 11 25 11 14 0 25 4 25 8 0 14 -23 32 -40 32 -9
0 -21 -5 -28 -12z"/>
<path d="M2330 1550 c-15 -28 4 -62 32 -58 12 2 23 8 25 15 3 7 -3 9 -16 6
-12 -3 -21 -1 -21 6 0 6 11 11 25 11 28 0 34 24 9 34 -27 11 -43 7 -54 -14z
m47 -6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M2500 1550 c-23 -43 30 -81 61 -43 13 15 11 16 -13 9 -24 -5 -28 -3
-28 14 0 17 4 19 28 14 24 -7 26 -6 13 9 -18 23 -48 21 -61 -3z"/>
<path d="M2670 1555 c-26 -32 13 -81 48 -59 34 22 22 74 -18 74 -10 0 -23 -7
-30 -15z m46 -16 c10 -17 -13 -36 -27 -22 -12 12 -4 33 11 33 5 0 12 -5 16
-11z"/>
<path d="M2840 1529 c0 -44 17 -53 22 -11 2 16 9 27 18 27 9 0 16 -11 18 -27
4 -38 22 -36 22 2 0 20 5 30 15 30 10 0 15 -10 15 -30 0 -16 5 -30 11 -30 6 0
9 16 7 38 l-3 37 -62 1 -63 1 0 -38z"/>
<path d="M3083 1563 c-20 -8 -15 -21 15 -37 l27 -15 -24 -1 c-18 0 -22 -3 -14
-11 13 -13 53 -2 53 15 0 7 -12 17 -27 23 -26 10 -27 11 -5 12 13 1 20 6 17
11 -7 11 -22 12 -42 3z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
